<template>
  <!-- 作品 案例 -->
  <div class="works">
    <div
      class="current-nav centerText"
      :style="{
        background: `url(${zpData.zuopinImg}) 100%/100% no-repeat`
      }"
    >
      <div v-if="!currentId">
        <h1>{{ zpData.basicTitle }}</h1>
        <h2>{{ zpData.basicText }}</h2>
        <span>作品</span>
      </div>
      <div v-else>
        <h1>{{ zpxqData.title ? zpxqData.title : zpData.basicTitle }}</h1>
        <h2>
          {{ zpxqData.subtitle ? zpxqData.subtitle : zpData.basicText }}
        </h2>
        <div class="current_icon" v-if="zpxqData.type_name == '网站作品'">
          <a :href="zpxqData.link" class="centerText" target="_blank">
            <i class="el-icon-monitor"></i>
          </a>
          <a
            class="centerText"
            @click=";(currentEwm = zpxqData.h5qrcode_image), (xcx_ewm = true)"
          >
            <i class="el-icon-mobile-phone"></i>
          </a>
        </div>
        <div class="current_icon" v-else>
          <a
            class="centerText"
            @click=";(currentEwm = zpxqData.xcxqrcode_image), (xcx_ewm = true)"
          >
            <i class="el-icon-mobile-phone"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="works-nav" v-if="!currentId">
      <div class="works-nav-upper">
        <ul>
          <li
            v-for="(item, idx) in dataList"
            :key="idx"
            :class="[active == idx ? 'on' : '']"
            @click="onChangeDiv(item, idx)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="works-nav-lower">
        <ul class="lower-ul">
          <li
            v-for="(item, idx) in alList"
            :key="idx"
            @click="toDetails(item, idx)"
          >
            <a :href="'#/works?currentId=' + item.id">
              <div class="li_div">
                <div class="li_div-img">
                  <div class="li_div-img-fd">
                    <div class="img-ico">
                      <i
                        class="el-icon-caret-right"
                        style="font-size:35px;color:#fff"
                      ></i>
                    </div>
                  </div>
                  <img
                    style="width: 100%;"
                    :src="item.site_images"
                    :alt="item.title + '网站作品'"
                  />
                </div>
                <div class="li_div-nr">
                  <h4>{{ item.title }}</h4>
                  <div class="cong-ico">
                    <i class="el-icon-monitor" style="font-size:25px"> </i
                    ><i class="el-icon-mobile-phone" style="font-size:22px"></i>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <onLoading :show="loading"></onLoading>
        </ul>
        <div class="all-pagination">
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="tabPag.page"
            background
            :page-size="8"
            layout="prev, pager, next,total"
            :total="tabPag.total"
            v-if="tabPag.total > 6"
          >
          </el-pagination>
          <span class="no_meet_conditions" v-else>暂无更多数据</span>
        </div>
      </div>
    </div>
    <div class="works-nav2" v-else>
      <div class="works-nav2-container">
        <div class="container_head">
          <div class="container_head_left">
            <span>当前位置：</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/works' }"
                ><span @click="currentId = ''">作品</span></el-breadcrumb-item
              >
              <el-breadcrumb-item>{{ zpxqData.type_name }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="container_head_right">
            <span>{{ zpxqData.type_name }}</span>
          </div>
        </div>
        <div class="container_img" v-html="zpxqData.maincontent"></div>
      </div>
    </div>
    <div
      class="works_xcx_ewm centerText"
      v-if="xcx_ewm"
      @click="xcx_ewm = false"
    >
      <div class="works_xcx_ewm_nav">
        <img :src="currentEwm" alt="" style="width:100%" />
        <p>使用微信扫码查看案例</p>
      </div>
    </div>
  </div>
</template>
<script>
import onLoading from '@/common/Loading/onLoading.vue'
import { getPageMsg, getCaseList, getCaseDetail, getCaseTypes } from '@/api/api'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      loading: false,
      zpData: {},
      dataList: [],
      alList: [],
      active: 0,
      tabPag: {
        page: 1,
        total: 0
      },
      currentId: '',
      zpxqData: {},
      currentEwm: '',
      xcx_ewm: false,
      project_id: ''
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.getData()
        } else {
          this.currentId = ''
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      this.project_id = sessionStorage.getItem(this.changeData() + 'project')
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.toDetails({ id: this.currentId })
      } else {
        this.currentId = ''
      }
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.zpData = res.data.data.zuopin_head
        }
      })
      this.getDataList(this.tabPag.page, '', this.project_id)
      this.getTypeList()
    },
    getTypeList () {
      getCaseTypes(this.project_id).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          this.dataList = res.data.data
        }
      })
    },
    getDataList (i, x, d) {
      getCaseList(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.loading = false
          this.alList = res.data.data.list
          this.tabPag.total = res.data.data.total
        }
      })
    },
    toDetails (e, i) {
      this.currentId = e.id
      getCaseDetail(e.id, this.project_id).then(res => {
        if (res.data.code == 1) {
          this.zpxqData = res.data.data
        }
      })
    },
    onChangeDiv (e, i) {
      this.active = i
      this.loading = true
      this.getDataList(1, e.id, this.project_id)
    },
    handleCurrentChange (val) {
      this.loading = true
      this.getDataList(val, '', this.project_id)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.works {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .works-nav {
    .works-nav-upper {
      text-align: center;
      padding: 20px 0;
      background-color: #fff;
      border-bottom: 1px solid #eaeaea;
      ul {
        display: flex;
        justify-content: center;
        li {
          display: block;
          padding: 10px 30px;
          color: #373737;
          margin: 0 10px;
          border-radius: 30px;
          cursor: pointer;
          white-space: nowrap;
        }
        .on,
        li:hover {
          background-color: #e62022;
          color: #fff;
        }
      }
    }
    .works-nav-lower {
      padding: 35px 0 60px;
      .lower-ul {
        width: calc(100% - -16px);
        margin: 0 -8px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        li {
          width: 25%;
          margin-bottom: 16px;
          .li_div {
            padding: 0 8px;
            .li_div-img {
              position: relative;
              .li_div-img-fd {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                .img-ico {
                  background-color: rgba(230, 32, 34, 0.9);
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: auto;
                  width: 75px;
                  height: 75px;
                  border-radius: 50%;
                  transform: skew(30deg, 10deg);
                  opacity: 0;
                  transition: 0.5s;
                }
              }
            }
            .li_div-nr {
              overflow: hidden;
              height: 60px;
              padding: 0 10px 0 10px;
              background-color: #f1f1f1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                color: #000;
              }
              i {
                color: #666;
                margin: 0 5px;
              }
            }
          }
        }
        li:hover {
          .li_div-img-fd {
            .img-ico {
              transform: skew(0deg, 0deg) !important;
              opacity: 1 !important;
            }
          }
          .li_div-nr {
            background-color: #e62022 !important;
            transform: 1s;
            h4 {
              color: #fff !important;
            }
            i {
              color: #fff !important;
              margin: 0 5px;
            }
          }
        }
      }
      .lower-pagination {
        width: 100%;
        text-align: center;
        padding-top: 35px;
        .el-pagination {
          .btn-prev,
          .btn-next {
            border: 1px solid #ddd;
            box-sizing: border-box;
            margin: 0;
            padding: 0 10px;
            height: 30px;
          }
          .el-pager {
            li {
              border: 1px solid #ddd;
              box-sizing: border-box;
              min-width: 36px !important;
              height: 30px !important;
            }
          }
          .el-pagination__total {
            margin: 0 10px;
          }
        }
      }
    }
  }
  .works-nav2 {
    background-color: #f5f5f5;
    padding-bottom: 70px;
    .works-nav2-container {
      width: 86%;
      min-width: 1200px;
      margin: 0 auto;
      .container_head {
        padding: 18px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .container_head_left {
          display: flex;
          color: #333;
          font-size: 14px;
        }
        .container_head_right {
          span {
            display: inline-block;
            padding: 15px 50px;
            color: #fff;
            font-size: 16px;
            background-color: #e62022;
          }
        }
      }
      .container_img {
        background-color: #fff;
        min-height: 600px;
        /deep/img {
          display: block;
          max-width: 86%;
          height: auto !important;
          margin: auto;
          padding: 80px 0;
        }
      }
    }
  }
  .works_xcx_ewm {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .works_xcx_ewm_nav {
      background-color: #fff;
      width: 320px;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
      p {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
  .el-breadcrumb__separator {
    color: #333 !important;
  }
}
</style>
